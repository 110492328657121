<script>
import DefaultMixin from '../mixin'
import Pedidos from "components/fenaju/components/dashboard/Pedidos.vue"
import RequerAtencao from "components/fenaju/components/dashboard/RequerAtencao.vue"
import Stats from "components/fenaju/components/dashboard/Stats.vue"
import Leitor from "components/fenaju/components/dashboard/Leitor.vue"
import Resumo from "components/fenaju/components/dashboard/Resumo.vue"

export default {
  name: 'ConsoleDashboard',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    Resumo,
    Leitor,
    Stats,
    RequerAtencao,
    Pedidos
  },
  data() {
    return {
      menuActive: 'suporte'
    }
  },
  computed: {
  },
  beforeMount () {
  },
  watch: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Console',
    name: 'Console'
  }
}
</script>

<template>
    <div class="console-dashboard">
      <div class="ev2-body">
        <div class="content-limit">
          <div class="content flex">
            <pedidos />
            <requer-atencao />
            <stats />
          </div>
          <div class="content flex">
            <leitor />
            <resumo />
          </div>
        </div>
      </div>
    </div>
</template>
